import * as React from "react";
import { Link, Router } from "react-router-dom";
import history from "../../../../routes/history";

export const LinkTemplate: React.FC<{ path: string; label: string }> = ({
  path,
  label,
}) => {
  return (
    <Router history={history}>
      <Link to={path} className="link">
        {label}
      </Link>
    </Router>
  );
};
