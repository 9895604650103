import * as React from "react";
import { SnackbarContextProvider } from "../../../../infraestructure/data/contexts/snackbar";
import { darktheme, theme } from "../../../../infraestructure/core/lib/theme";
import { AppRoutes } from "../../../routes/AppRoutes";
import {useLocation} from 'react-router-dom'
import { SnackbarProvider } from "notistack";
export const SnackbarProviderCustom: React.FC<{ darkMode}> = ({darkMode}) => {
    const {pathname} = useLocation();

    return (
            <SnackbarProvider
                anchorOrigin={{
                    vertical: pathname === "/workshift/calendar" ? "bottom" : "top",
                    horizontal: "right",
                }}
                autoHideDuration={3200}
            >
                <SnackbarContextProvider>
                    <main
                        style={{
                            backgroundColor: darkMode
                                ? darktheme.palette.background.default
                                : theme.palette.background.default,
                        }}
                    >
                        <AppRoutes />
                    </main>
                </SnackbarContextProvider>
            </SnackbarProvider>
    )
}
