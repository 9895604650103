import styled from "styled-components";

export const UserMenuSection = styled.div``;

export const UserNameSection = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const UserDescription = styled.div`
  font-size: 14px;
`;

export const LogoutLabel = styled.span`
  font-weight: 500;
`;
