import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import { RouteWithAuth } from "../components/hocs/RouteWithAuth";

export const AppRoutes: React.FC = () => {
  return (
    <>
      <React.Suspense fallback={"Loading..."}>
        <Switch>
          {routes.map(({ path, Component, authRequired, exact }, index) =>
            authRequired ? (
              <RouteWithAuth
                component={Component}
                path={path}
                key={index}
                exact={exact}
              />
            ) : (
              <Route
                component={Component}
                path={path}
                key={index}
                exact={exact}
              />
            )
          )}
        </Switch>
      </React.Suspense>
    </>
  );
};
