import React from "react";
import {
  List,
  Divider,
  IconButton,
  ListItem,
  Drawer,
  ListItemIcon,
  Grid,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { ExitToApp } from "@material-ui/icons";
import { MENU_ITEMS } from "../../../../routes/menu";
import { useStyles } from "../style";
import { useHistory } from "react-router-dom";
import {
  UserMenuSection,
  LogoutLabel,
  UserNameSection,
  UserDescription,
} from "../style/components";
import { getRouteToGo, isSelected } from "../../../../routes/history";
import { useTranslation } from "react-i18next";
import { useAuthValue } from "../../../../../infraestructure/data/contexts/auth";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { TimeLapseIcon } from "../../../../assets/icons/TimeLapse";
import { AbsenceIcon } from "../../../../assets/icons/AbsenceIcon";
import { LynxviewIcon } from "../../../../assets/icons/LynxviewIcon";
import { ClockIcon } from "../../../../assets/icons/InfoIcon";
import { ToRouteIcon } from "../../../../assets/icons/ToRouteIcon";

const iconMenu = (icon) => {
  switch (icon) {
    case "user":
      return <AccountCircleIcon style={{ color: "black" }} />;
    case "configuration":
      return <SettingsIcon style={{ color: "black" }} />;
    case "absences":
      return <AbsenceIcon />;
    case "lynxview":
      return <LynxviewIcon />;
    case "home":
      return <AssessmentIcon style={{ color: "black" }} />;
    case "workshift":
      return <TimeLapseIcon />;
    case "staffHolidays":
      return <AbsenceIcon />;
    case "workshiftTemplate":
      return <ClockIcon />;
    case "calendar":
      return <></>;
    default:
      return <></>;
  }
};

export const Menu: React.FC<{
  open: boolean;
  setOpen: (Boolean) => void;
  route: any;
}> = ({ open, setOpen, route }) => {
  const { logout, getUser } = useAuthValue();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const user = getUser();

  const [showBackButton, setShowBackButton] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(MENU_ITEMS.list);
  const [historicMenu] = React.useState([MENU_ITEMS]);
  const [titleMenu, setTitleMenu] = React.useState(MENU_ITEMS.title);

  return (
    <>
      {!route.startsWith("/resetPassword") && !route.startsWith("/rememberPassword") && !route.startsWith("/auth") && !route.startsWith("/auth") && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.drawerLeftHeader}>
              {showBackButton && (
                <IconButton
                  onClick={() => {
                    historicMenu.pop();
                    setTitleMenu(historicMenu[historicMenu.length - 1].title);
                    setActiveMenu(historicMenu[historicMenu.length - 1].list);
                    setShowBackButton(historicMenu.length > 1);
                  }}
                >
                  <ArrowBackIcon color="secondary" />
                </IconButton>
              )}
            </div>
            <div className={classes.drawerHeader}>
              {route != "/" && (
                <IconButton
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseRounded style={{ fill: "black" }} />
                </IconButton>
              )}
            </div>
          </div>

          <div
            style={{
              fontWeight: 500,
              fontSize: "20px",
              margin: "0 0 .75em .75em",
            }}
          >
            {t(titleMenu)}
          </div>
          <Divider style={{ backgroundColor: "rgba(0, 0, 0, 0.38)" }} />

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                {activeMenu.map(({ text, id, menu, icon, role }, index) => {
                  return (
                    <div key={text} >
                      {(!role ||
                        (user &&
                          user.roles.some((x) => role.includes(x.value)))) && (
                        <ListItem
                          button

                          onClick={() => {
                            if (!menu) {
                              setOpen(false);
                              if (id === "userAbsences") {
                                let path = getRouteToGo(id);
                                path = path.replace(":id", user.id);
                                path = path.replace(":requestId", "0");
                                history.push(path);
                              } else {
                                history.push(getRouteToGo(id));
                              }
                            } else {
                              setShowBackButton(true);
                              historicMenu.push(menu);
                              setActiveMenu(menu.list);
                              setTitleMenu(menu.title);
                            }
                          }}
                          className={
                            isSelected(history, id)
                              ? `${classes.drawerItem} ${classes.drawerItem}--selected`
                              : `${classes.drawerItem}`
                          }
                        >
                          <Grid
                              container
                              direction="row"
                              style={{display:"flex", alignItems:"center", alignContent:"center",height:'24px'}}
                          >
                            <Grid item xs={menu ? 11 : 12}>
                              <div>
                                {icon && (<ListItemIcon style={{verticalAlign:"middle"}}>{iconMenu(icon)}</ListItemIcon>)}
                                <span className={classes.drawerItemText} >
                                  {t(text)}
                                </span>
                              </div>
                            </Grid>
                            {menu && (
                                <Grid item xs={1}>
                                  {icon && (<ListItemIcon style={{verticalAlign:"middle", minWidth:25}}><ToRouteIcon/></ListItemIcon>)}
                                </Grid>
                            )}
                          </Grid>
                        </ListItem>
                      )}
                    </div>
                  );
                })}
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ position: "absolute", bottom: 0, width: "100%" }}
            ></Grid>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid
              item
              xs={12}
              style={{ position: "absolute", bottom: 0, width: "100%" }}
            >
              <List>
                {user && (
                  <ListItem
                    style={{ marginBottom: "1.5em" }}
                    key={"user"}
                    button
                    onClick={() => {
                      setOpen(false);
                      history.push(`/users/edit/${user.email}`);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar />
                    </ListItemAvatar>
                    <UserMenuSection>
                      <UserNameSection>
                        {user.firstName} {user.lastName}
                      </UserNameSection>
                      <UserDescription>{user.email}</UserDescription>
                    </UserMenuSection>
                  </ListItem>
                )}
                <Divider style={{ backgroundColor: "rgba(0, 0, 0, 0.38)" }} />
                <ListItem
                  key={"logout"}
                  button
                  style={{ height: "80px" }}
                  onClick={() => {
                    logout(history);
                    setOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>

                  <LogoutLabel>{t("auth.logout")}</LogoutLabel>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Drawer>
      )}
    </>
  );
};
