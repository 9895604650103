import { Alert as MuiAlert, Color } from "@material-ui/lab";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { LinkTemplate } from "../templates/text/LinkTemplate";
import { useStyles } from "./style";

export const Alert: React.FC<{
  type: Color;
  text: string;
  path?: string;
  pathName?: string;
  onClose: (e) => void;
}> = ({ type, text, onClose, path, pathName }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MuiAlert
      className={classes[type]}
      onClose={onClose}
      severity={type}
      elevation={6}
      variant="filled"
    >
      <div className={classes.messageRoot}>
        {t(text)}
        {path && pathName && (
          <div className={classes.linkLabel}>
            <LinkTemplate path={path} label={pathName}></LinkTemplate>
          </div>
        )}
      </div>
    </MuiAlert>
  );
};
