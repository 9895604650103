import {
  signIn,
  singUp,
  resetPassword,
  getUser,
  getUserByEmail,
  forgotPassword,
  getUsers,
  postUser,
  putUser,
  patchUser,
  checkPassword,
  postNewPassword,
  putUserByEmail, getUsersList,
} from "../../core/services/auth";
import { pathBuilder } from "../../core/utils/patch-builder";

export const signInProvider = (body: any) => signIn(body);
export const singUpProvider = (body: any) => {body.companyId=1; return singUp(body)}
export const resetPasswordProvider = (body: any) => resetPassword(body);

export const getUserProvider = () => getUser();
export const getUserByEmailProvider = (email: string) => getUserByEmail(email);
export const forgotPasswordProvider = (email: string) => forgotPassword(email);

export const createUserProvider = (body) => postUser(body);
export const updateUserProvider = (body) => putUser(body);
export const patchUserProvider = (body) => {
  const email = body.email;
  delete body.email;
  delete body.id;

  return patchUser(pathBuilder(body), email);
};
export const updateUserByEmailProvider = (email, body) =>
  putUserByEmail(email, body);

export const checkUserPasswordProvider = (email, body) =>
  checkPassword(email, body);
export const createNewUserPasswordProvider = (email, body) =>
  postNewPassword(email, body);

export const getUsersProvider = (filters, sort, pagination) => {
  return getUsers(filters, sort, pagination);
};
export const getSelectUserProvider = () => {
  return getUsersList();
}
export const dropdownValues = async () => {
  const userIds = await getSelectUserProvider()
  return {userIds}
}

