import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AppBar, Toolbar, IconButton, Tooltip, Chip } from "@material-ui/core";
import { useStyles } from "../style";
import { AppBarIcon, BackIcon } from "../../../../assets/icons/AppBarIcon";
import { Subtitle1 } from "../../font/Subtitle";
import { useHistory, useParams } from "react-router";
import Switch from "@mui/material/Switch";

export const HeaderBar: React.FC<{
  open: boolean;
  setOpen: (Boolean) => void;
  darkMode: boolean;
  setDarkMode: any;
  route: any;
}> = ({ setOpen, open, route, darkMode, setDarkMode }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {!route.startsWith("/resetPassword") && !route.startsWith("/rememberPassword") && !route.startsWith("/login") && !route.startsWith("/auth") && route != "/" && (
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <div>
              {route && route.back && (
                <Tooltip title={t("general.back")}>
                  <IconButton
                    onClick={() =>
                      route.back === "history"
                        ? history.goBack()
                        : history.push(route.back)
                    }
                    size="small"
                  >
                    <BackIcon color={"#C7940D"} height={12} width={7.5} />
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <div
              style={{
                position: "absolute",
                right: 0,
                marginRight: 24,
                marginTop: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AppBarIcon width={56} height={25} />
            </div>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};
