import React from "react";
import { Router } from "react-router-dom";
import historyBrowser from "./routes/history";
import { AuthProvider } from "../infraestructure/data/contexts/auth";
import { Header } from "./components/common/header/Header";
import { ThemeProvider } from "@material-ui/styles";
import { theme, darktheme } from "../infraestructure/core/lib/theme";
import { SnackbarProviderCustom } from "./components/common/SnackbarProvider/SnackbarProvider";

function App() {
  const [darkMode, setDarkMode] = React.useState(false);

  return (
    <div className="App">
      <AuthProvider>
        <ThemeProvider theme={darkMode ? darktheme : theme}>
          <Router history={historyBrowser}>
            <Header darkMode={darkMode} setDarkMode={setDarkMode} />
            <SnackbarProviderCustom darkMode={darkMode}/>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
