import { PaginationType } from "../models/commons/Pagination";
import { sortType } from "../models/commons/Sort";
import { buildRequestFiltersAndSort } from "../utils/request-params-builder";
import Api from "./api";

const requestOptions = {
  baseUrl: process.env.API_URL,
  defaultOptions: {},
};

const api = new Api(requestOptions);

export const signIn = (body: any) =>
  api.post("/api/v1.1/Auth/SignIn", { body });
export const singUp = (body: any) =>
  api.post("/api/v1.1/Auth/SignUp", { body });
export const resetPassword = (body: any) =>
  api.post(`/api/v1.1/Auth/User/ResetPassword`, { body });

export const getUser = () => api.get("/api/v1.1/Auth/User/Info");
export const getUserByEmail = (email: string) =>
  api.get(`/api/v1.1/Auth/User/${email}/Info`);

export const forgotPassword = (email: string) =>
  api.get(`/api/v1.1/Auth/User/${email}/ForgotPassword`);

export const postUser = (body) => api.post(`/api/v1.1/Auth/User`, { body });
export const putUser = (body) => api.put(`/api/v1.1/Auth/User`, { body });
export const patchUser = (body, email) =>
  api.patch(`/api/v1.1/Auth/User/${email}`, { body });
export const putUserByEmail = (email, body) =>
  api.put(`/api/v1.1/Auth/User/${email}`, { body });

export const checkPassword = (email, body) =>
  api.post(`/api/v1.1/Auth/User/${email}/CheckPassword`, { body });
export const postNewPassword = (email, body) =>
  api.post(`/api/v1.1/Auth/User/${email}/ChangePassword`, { body });

export const putUserHolidays = (id, body) =>
  api.put(`/api/v1.1/Auth/User/${id}/holidays`, { body });

export const getUsers = (
  filters: any,
  sort: sortType[],
  pagination: PaginationType
) => {
  const q = buildRequestFiltersAndSort(filters, sort);

  return api.get(`/api/v1.1/Auth/User?${q}`, pagination);
};
export const getUsersList = () => {
  return api.get(`/api/v1.1/Auth/SelectList`);
};
