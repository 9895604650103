import * as React from "react";

export const Home = React.lazy(() => import("../modules/Index"));
/*export const WorkShiftsList = React.lazy(
  () => import("../modules/workshifts/layouts/List")
);*/
export const NewWorkShift = React.lazy(
  () => import("../modules/workshifts/layouts/New")
);
export const EditWorkShift = React.lazy(
  () => import("../modules/workshifts/layouts/Edit")
);
export const UserList = React.lazy(
  () => import("../modules/user/layouts/List")
);
export const EditUser = React.lazy(
  () => import("../modules/user/layouts/Edit")
);
export const Login = React.lazy(() => import("../modules/auth/layouts/Login"));
export const Register = React.lazy(
  () => import("../modules/auth/layouts/Register")
);
export const RememberPassword = React.lazy(
  () => import("../modules/auth/layouts/RememberPassword")
);
export const ResetPassword = React.lazy(
  () => import("../modules/auth/layouts/ResetPassword")
);

export const SigningCalendar = React.lazy(
  () => import("../modules/signing/layouts/Calendar")
);

export const WorkShiftsTemplatesList = React.lazy(
  () => import("../modules/workshiftTemplate/List")
);

export const StaffHolidays = React.lazy(
  () => import("../modules/holidays/StaffHolidays")
);
export const CalendarsList = React.lazy(
    () => import("../modules/calendar/layouts/List")
);
export const CalendarEdit = React.lazy(
    () => import("../modules/calendar/layouts/Edit")
);

export const UserAbsences = React.lazy(
  () => import("../modules/holidays/UserAbsences")
);
