import React from "react";
import { HeaderBar } from "./partials/HeaderBar";
import { useStyles } from "./style";
import { Menu } from "./partials/Menu";
import {useLocation} from 'react-router-dom';

export const Header: React.FC<{ darkMode: boolean; setDarkMode: any }> = ({
  darkMode,
  setDarkMode,
}) => {
  const classes = useStyles();
  const {pathname} = useLocation();
  const [open, setOpen] = React.useState(pathname === "/");
    React.useEffect(() => {
        setOpen(pathname === "/")
    }, [pathname]);

  return (
    <div className={classes.root}>
      <HeaderBar
        open={open}
        setOpen={setOpen}
        route={pathname}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <Menu open={open} setOpen={setOpen} route={pathname} />
    </div>
  );
};
