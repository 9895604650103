import * as React from "react";
import { createReducer } from "../../core/utils/createReducer";
import { Account } from "../../core/models/auth/user";

import {
  storage,
  STORAGE_TOKEN_KEY,
  STORAGE_USER,
} from "../../core/helpers/constants";
import {
  signInProvider,
  getUserProvider,
  singUpProvider,
  resetPasswordProvider,
  forgotPasswordProvider,
} from "../../data/providers/auth";

type AuthState = {
  user: Account;
  isLoading: boolean;
  error: string;
  dispatch: React.Dispatch<any>;
  isAuthenticated: () => boolean;
  signin: (history?: any) => void;
  logout: (history?: any) => void;
  saveUserPreferences: (user: Account) => void;
  saveSession: (token: string) => void;
  login: (body: any) => Promise<boolean>;
  signup: (body: any) => Promise<boolean>;
  resetPassword: (body: any) => Promise<boolean>;
  getUser: () => any;
};

const reducer = createReducer({ payload: "user" });
const initialState = {
  user: null,
  error: "",
  isLoading: false,
  dispatch: () => {},
  isAuthenticated: undefined,
  signin: (history) => {},
  logout: undefined,
  saveUserPreferences: undefined,
  saveSession: undefined,
  login: undefined,
  getUser: undefined,
  signup: undefined,
  resetPassword: undefined,
};
export const AuthContext = React.createContext<AuthState>(initialState);

export const AuthProvider = ({ children }) => {
  const [{ user, isLoading, error }, dispatch]: [
    AuthState,
    React.Dispatch<any>
  ] = React.useReducer(reducer, initialState);

  const isAuthenticated = () => {
    const token = localStorage.getItem(STORAGE_TOKEN_KEY);
    return !!token;
  };

  const signin = async (history) => {
    dispatch({ type: "fetching" });
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/register" &&
      history
    ) {
      history.push("/login");
    }
  };

  const login = async (body) => {
    const response = await signInProvider(body);
    if (response) {
      saveSession(response);
      const userAux = await getUserProvider();
      if (userAux) {
        dispatch({ type: "success", payload: { user: userAux } });
        saveUserPreferences(userAux);
        return true;
      }
    }
    return false;
  };

  const logout = (history) => {
    dispatch({ type: "reset" });
    storage.clear();
    history.push("/login");
  };

  const getUser = () => {
    return JSON.parse(storage.getItem(STORAGE_USER));
  };

  const saveUserPreferences = (user: any | Account) => {
    storage.setItem(STORAGE_USER, JSON.stringify({ ...user }));
  };

  const saveSession = (token: any) => {
    storage.setItem(STORAGE_TOKEN_KEY, token);
  };

  const signup = async (body) => {
    const response = await singUpProvider(body);
    if (response) {
      saveSession(response);
      const userAux = await getUserProvider();
      if (userAux) {
        dispatch({ type: "success", payload: { user: userAux } });
        saveUserPreferences(userAux);
        return true;
      }
    }
    return false;
  };

  const resetPassword = async (body) => {
    const response = await resetPasswordProvider(body);
    if (response) {
      saveSession(response);
      const userAux = await getUserProvider();
      if (userAux) {
        dispatch({ type: "success", payload: { user: userAux } });
        saveUserPreferences(userAux);
        return true;
      }
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        error,
        dispatch,
        isAuthenticated,
        signin,
        logout,
        saveUserPreferences,
        saveSession,
        login,
        getUser,
        signup,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuthValue = () => React.useContext<AuthState>(AuthContext);
