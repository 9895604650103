export const createReducer =
  ({ payload }) =>
  (state, action) => {
    switch (action.type) {
      case "fetching": {
        return { ...state, isLoading: true, error: "" };
      }
      case "success": {
        return { ...state, isLoading: false, [payload]: action.payload };
      }
      case "error": {
        return { ...state, isLoading: false, error: action.error.message };
      }
      default: {
        return state;
      }
    }
  };
