import {
  Home,
  Login,
  Register,
  RememberPassword,
  ResetPassword,
  UserList,
  EditUser,
  NewWorkShift,
  EditWorkShift,
  SigningCalendar,
  WorkShiftsTemplatesList,
  StaffHolidays,
  UserAbsences, CalendarsList, CalendarEdit,
} from "./lazyRoutes";

export const routes = [
  {
    id: "home",
    path: "/",
    name: "pages.home",
    authRequired: true,
    exact: true,
    Component: Home,
  },
  {
    id: "workshift-new",
    path: "/workshifts/new",
    name: "pages.new.workshift.home",
    authRequired: true,
    exact: true,
    Component: NewWorkShift,
  },
  {
    id: "workshift-edit",
    path: "/workshifts/edit/:id",
    name: "pages.edit.workshift.home",
    authRequired: true,
    exact: true,
    Component: EditWorkShift,
  },
  {
    id: "calendar-list",
    path: "/calendars",
    name: "pages.calendar-list",
    authRequired: true,
    exact: true,
    Component: CalendarsList,
  },
  {
    id: "calendar-edit",
    path: "/calendars/edit/:id",
    name: "pages.calendar-edit",
    authRequired: true,
    exact: true,
    Component: CalendarEdit,
  },
  {
    id: "user-list",
    path: "/users",
    name: "pages.user-list",
    authRequired: true,
    exact: true,
    Component: UserList,
  },
  {
    id: "user-edit",
    path: "/users/edit/:email",
    name: "pages.user-edit",
    authRequired: true,
    exact: true,
    Component: EditUser,
  },
  {
    id: "auth-login",
    path: "/login",
    name: "pages.login",
    authRequired: false,
    exact: true,
    Component: Login,
  },
  {
    id: "admin",
    path: "/admin",
    name: "pages.signup",
    authRequired: true,
    exact: true,
    Component: Register,
  },
  {
    id: "auth-rememberPassword",
    path: "/rememberPassword",
    name: "pages.remember",
    authRequired: false,
    exact: true,
    Component: RememberPassword,
  },
  {
    id: "auth-resetPassword",
    path: "/resetPassword/:token/:user",
    name: "pages.newPasswod",
    authRequired: false,
    exact: true,
    Component: ResetPassword,
  },
  {
    id: "workshift-calendar",
    path: "/workshift/calendar",
    name: "pages.workshift.calendar",
    authRequired: true,
    exact: true,
    Component: SigningCalendar,
  },
  {
    id: "workshiftTemplateslist",
    path: "/workshifttemplateslist",
    name: "pages.workshifttemplatelist.home",
    authRequired: true,
    exact: true,
    Component: WorkShiftsTemplatesList,
  },
  {
    id: "staffHolidays",
    path: "/staff-holidays",
    name: "pages.holidays.staff",
    authRequired: true,
    exact: true,
    Component: StaffHolidays,
  },
  {
    id: "userAbsences",
    path: "/user/absences/:id/:requestId",
    name: "pages.user.absences",
    authRequired: true,
    exact: true,
    Component: UserAbsences,
  },
];
