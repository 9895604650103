import * as React from "react";

export const InfoIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
        fill="url(#paint0_linear_576_10926)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_576_10926"
          x1="-3.87847"
          y1="-31.3343"
          x2="29.5583"
          y2="-31.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE01CD" />
          <stop offset="1" stopColor="#1472E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ClockIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.24 7.74999C15.07 6.57999 13.54 5.98999 12 5.98999V11.99L7.76 16.23C10.1 18.57 13.9 18.57 16.25 16.23C18.59 13.89 18.59 10.09 16.24 7.74999ZM12 1.98999C6.48 1.98999 2 6.46999 2 11.99C2 17.51 6.48 21.99 12 21.99C17.52 21.99 22 17.51 22 11.99C22 6.46999 17.52 1.98999 12 1.98999ZM12 19.99C7.58 19.99 4 16.41 4 11.99C4 7.56999 7.58 3.98999 12 3.98999C16.42 3.98999 20 7.56999 20 11.99C20 16.41 16.42 19.99 12 19.99Z"
        fill="black"
      />
    </svg>
  );
};
