import { ListMenuType } from "../../infraestructure/core/models/commons/Menu";
import {
  USER_ROLE,
  ADMIN_ROLE,
} from "../../infraestructure/core/helpers/constants";

const CONFIGURATION_MENU_ITEMS: ListMenuType = {
  title: "Configuración",
  list: [
    {
      id: "workshiftTemplateslist",
      text: "pages.workshiftTemplateslist",
      role: [ADMIN_ROLE, USER_ROLE],
    },
  ],
};

const LYNXVIEW_MENU_ITEMS: ListMenuType = {
  title: "pages.lynxview",
  list: [
    {
      id: "staffHolidays",
      text: "general.holidays.staff",
      role: [ADMIN_ROLE, USER_ROLE],
    },
    {
      id: "user-list",
      text: "general.users",
      role: [ADMIN_ROLE],
    },
    {
      id: "calendar-list",
      text: "general.calendars",
      role: [ADMIN_ROLE],
    },
  ],
};

export const MENU_ITEMS: ListMenuType = {
  title: "general.main",
  list: [
    {
      id: "workshift-calendar",
      text: "general.workshift.calendar",
      icon: "workshift",
      role: [ADMIN_ROLE, USER_ROLE],
    },
    {
      id: "userAbsences",
      text: "general.absences",
      icon: "absences",
      role: [ADMIN_ROLE, USER_ROLE],
    },

    {
      id: "configuration",
      text: "pages.configuration",
      menu: CONFIGURATION_MENU_ITEMS,
      icon: "configuration",
      role: [ADMIN_ROLE, USER_ROLE],
    },
    {
      id: "lynxview",
      text: "pages.lynxview",
      menu: LYNXVIEW_MENU_ITEMS,
      icon: "lynxview",
      role: [ADMIN_ROLE, USER_ROLE],
    },
  ],
};
