import React from "react";

export const TimeLapseIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.24 7.74999C15.07 6.57999 13.54 5.98999 12 5.98999V11.99L7.76 16.23C10.1 18.57 13.9 18.57 16.25 16.23C18.59 13.89 18.59 10.09 16.24 7.74999ZM12 1.98999C6.48 1.98999 2 6.46999 2 11.99C2 17.51 6.48 21.99 12 21.99C17.52 21.99 22 17.51 22 11.99C22 6.46999 17.52 1.98999 12 1.98999ZM12 19.99C7.58 19.99 4 16.41 4 11.99C4 7.56999 7.58 3.98999 12 3.98999C16.42 3.98999 20 7.56999 20 11.99C20 16.41 16.42 19.99 12 19.99Z"
        fill="black"
      />
    </svg>
  );
};
