import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRoot: {
      display: "flex",
    },
    info: {
      backgroundColor: "#63707D",
    },
    error: {
      backgroundColor: "#B00020",
    },
    success: {
      backgroundColor: "#219653",
    },
    linkLabel: {
      marginLeft: 3,
    },
  })
);
