import { colors } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { esES } from "@material-ui/data-grid";

export const theme = createTheme(
  {
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          background: "linear-gradient(90.1deg, #D814D2 -0.06%, #5A51E1 100%)",
          backgroundColor: null,
        },
      },
      MuiStepIcon: {
        root: {
          "&$completed": {
            color: "#424A52",
          },
          "&$active": {
            color: "#C7940D",
          },
          color: "#BDBDBD",
        },
        active: {},
        completed: {},
      },
    },
    palette: {
      background: {
        paper: "#FFF",
        default: "#FFF",
      },
      primary: {
        main: "#424A52",
      },
      secondary: {
        main: "#CC37CC",
        dark: "#565A62",
      },
      error: {
        main: colors.red[500],
      },
      warning: {
        main: colors.yellow[700],
      },
      success: {
        main: colors.green[500],
      },
      action: {
        active: "rgba(33, 33, 33, 0.6)",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 750,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  esES
);

export const darktheme = createTheme(
  {
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          background: " linear-gradient(90.1deg, #D814D2 -0.06%, #5A51E1 100%)",
          backgroundColor: null,
          color: "#fff",
        },
      },
      MuiStepIcon: {
        root: {
          "&$completed": {
            color: "#424A52",
          },
          "&$active": {
            color: "#C7940D",
          },
          color: "#BDBDBD",
        },
        active: {},
        completed: {},
      },
    },
    palette: {
      type: "dark",
      text: {
        primary: "#FFFFFF",
      },
      background: {
        default: "#403247",
      },
      primary: {
        main: "#424A52",
      },
      secondary: {
        main: "#CC37CC",
      },
      error: {
        main: colors.red[500],
      },
      warning: {
        main: colors.yellow[700],
      },
      success: {
        main: colors.green[500],
      },
      action: {
        active: "rgba(33, 33, 33, 0.6)",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 750,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  esES
);
