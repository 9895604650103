import { Color } from "@material-ui/lab";
import * as React from "react";
import { useSnackbar } from "notistack";
import { Snackbar } from "../../../domain/components/common/snackbar/Snackbar";

interface SnackbarParams {
  type: Color;
  text: string;
  code?: any;
  path?: string;
  pathName?: string;
}

interface SnackbarState {
  showSnackbar: (args: SnackbarParams) => void;
}

const initialState = {
  showSnackbar: undefined,
};

export const SnackbarContext = React.createContext<SnackbarState>(initialState);
export const SnackbarContextProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notistackRef = React.createRef();

  const onHandleCloseSnackbar = () => {
    closeSnackbar(notistackRef.current.key);
  };

  const showSnackbar = (params: SnackbarParams) => {
    enqueueSnackbar(params.text, {
      ref: notistackRef,
      content: (
        <div>
          <Snackbar
            type={params.type}
            message={params.text}
            path={params.path}
            pathName={params.pathName}
            handleClose={onHandleCloseSnackbar}
          ></Snackbar>
        </div>
      ),
    });
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
export const useSnackbarValue = () =>
  React.useContext<SnackbarState>(SnackbarContext);
