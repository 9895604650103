import * as React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuthValue } from "../../../infraestructure/data/contexts/auth";

export const RouteWithAuth: React.FC<{
  component: React.FC;
  path?: string;
  exact: boolean;
  rol?: string;
}> = ({ component, path, exact, rol }) => {
  const { isAuthenticated, user, signin } = useAuthValue();
  const history = useHistory();

  const authProcess = async () => {
    if (!user && !isAuthenticated()) {
      await signin(history);
    }
  };

  React.useEffect(() => {
    authProcess();
  }, []);

  return <Route component={component} path={path} exact={exact} />;
};
