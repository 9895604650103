import * as React from "react";
import { Alert } from "../alert/Alert";
import { Color } from "@material-ui/lab";

export const Snackbar: React.FC<{
  handleClose?: (key) => void;
  message: string;
  type: Color;
  path?: string;
  pathName?: string;
}> = ({ handleClose, message, type, path, pathName }) => {
  return (
    <Alert
      type={type}
      text={message}
      onClose={handleClose}
      path={path}
      pathName={pathName}
    />
  );
};
